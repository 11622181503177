<template>
  <v-container id="bookings" fluid tag="section" class="pt-0">
    <v-col>
      <base-material-card color="secondary" class="mt-0 mb-0 py-3" :header="false">
        <bookings></bookings>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
export default {
  components: {
    Bookings: () => import('@/components/dashboard/bookings/Bookings'),
  },
  data() {
    return {
      str: window.strings,
    }
  },
}
</script>
